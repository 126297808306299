import React, { Component } from 'react';
import { renderToString } from 'react-dom/server'

const Input = (props) => (
  <input {...props} style={{
    boxSizing: 'border-box',
    display: 'block',
    width: '260px',
    marginBottom: '5px',
    padding: '5px'
  }} />
);

const Table = React.forwardRef(({
  name,
  role,
  phone,
  email
}, ref) => {
  const textStyle = {
    display: 'inline',
    fontFamily: 'Helvetica, sans-serif',
    fontSize: '16px',
    color: 'white'
  };

  return (
    <table style={{ width: '600px' }} ref={ref}>
      <tbody>
        <tr>
          <td style={{
            padding: '20px 10px',
            width: '186px',
            backgroundColor: '#051f68',
            textAlign: 'center',
            verticalAlign: 'middle'
          }}>
            <img src="https://s3.amazonaws.com/grupotrapezio.com.br/assets/logos/13.png" width="180" height="100"  alt="Grupo Trapézio" />
          </td>

          <td style={{
            padding: '20px 30px',
            backgroundColor: '#051f68',
            verticalAlign: 'middle'
          }}>
              {name && <span style={{ ...textStyle, fontSize: '18px', fontWeight: 'bold', marginBottom: '20px' }}>{name}</span>}
              <br />
              {role && <span style={{ ...textStyle, fontSize: '15px', fontStyle: 'italic', marginBottom: '100px' }}>{role}</span>}
              <br />
              <br />
              {phone && <span style={{ ...textStyle, fontWeight: 300, marginBottom: '2px' }}>{phone}</span>}
              <br />
              {email && <a href={'mailto:' + email} style={{ ...textStyle, fontWeight: 300, textDecoration: 'none' }}>{email}</a>}
          </td>
        </tr>
      </tbody>
    </table>
  )
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      role: '',
      phone: '',
      email: '',
      showCopyMessage: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCopy = this.handleCopy.bind(this);

    this.tableRef = React.createRef();
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleCopy() {
    if (window.getSelection) {
      let range = document.createRange();
      range.selectNode(this.tableRef.current);

      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');

      this.setState({
        showCopyMessage: true
      });
    }
  }

  render() {
    const {
      name,
      role,
      phone,
      email,
      showCopyMessage
    } = this.state;

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', padding: '30px', width: '100%', maxWidth: '870px' }}>
        <div style={{ width: 'auto', marginRight: '10px' }}>
          <Input name="name" placeholder={'Seu Nome'} value={name} onChange={this.handleInputChange} />
          <Input name="role" placeholder={'Seu Cargo'} value={role} onChange={this.handleInputChange} />
          <Input name="phone" placeholder={'+55 (41) 99999-9999'} value={phone} onChange={this.handleInputChange} />
          <Input name="email" placeholder={'seuemail@grupotrapezio.com.br'} value={email} onChange={this.handleInputChange} />
        </div>

        <div style={{ width: '600px' }}>
          <Table {...this.state} ref={this.tableRef} />
        </div>

        <div style={{ width: '900px' }}>
          <textarea
            style={{ boxSizing: 'border-box', marginTop: '20px', width: '100%' }}
            rows="10"
            value={renderToString(<Table {...this.state} />)}
            readOnly>
          </textarea>
        </div>

        <div style={{ marginTop: '20px', width: '900px' }}>
          <button type="button" onClick={this.handleCopy}>Clique aqui para copiar a assinatura!</button>
          {showCopyMessage && <span style={{ display: 'block', margin: '5px 2px', fontFamily: 'sans-serif' }}>Copiado!</span>}
        </div>

        <div>
          <p style={{ display: 'block', margin: '5px 2px', fontFamily: 'sans-serif' }}><small>*Para quem usa o Mail do Mac: https://www.daretothink.co.uk/html-email-signature-in-apple-mail/</small></p>
        </div>
      </div>
    );
  }
}

export default App;
